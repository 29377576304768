<template>
    <form action="javascript:void(0);" method="post" class="order-form" @submit="createOrder" :class="{ hide: visible_type !== type }">
        <div class="single-panel limit">
            <input type="hidden" name="SymbolId" :value="symbol.metadata.id" />

            <!-- Only support types
            1: ExchangeTransactionType.BuyLimited
            2: ExchangeTransactionType.SellLimited
             -->
            <input type="hidden" name="Type" :value="orderType" />

            <div growing-ignore="true" class="row text-content">
                <div class="col-auto">{{ $t('exchange.available_balance') }}</div>
                <div class="col">{{ available_balance }} {{ isBuyOrder ? symbol.metadata.quote_symbol : symbol.metadata.base_symbol }}</div>
            </div>
            <div class="row has-input">
                <div class="col-auto text-label">{{ isBuyOrder ? $t('exchange.create_order_buy_price') : $t('exchange.create_order_sell_price') }}</div>
                <label growing-ignore="true" class="col position-relative">
                    <input
                        type="text"
                        v-model="order_price"
                        name="LimitedPrice"
                        maxlength="14"
                        data-val="true"
                        data-val-number="*"
                        data-val-required="*"
                        data-val-range="*"
                        data-val-range-min="0.00000001"
                        autocomplete="off"
                    />
                    <span class="currency upper text-label">{{ symbol.metadata.quote_symbol }}</span>
                </label>
            </div>
            <div class="row has-input">
                <div class="col-auto text-label">{{ isBuyOrder ? $t('exchange.create_order_buy_amount') : $t('exchange.create_order_sell_amount') }}</div>
                <label growing-ignore="true" class="col position-relative">
                    <input
                        type="text"
                        v-model="order_amount"
                        name="Amount"
                        maxlength="14"
                        data-val="true"
                        data-val-number="*"
                        data-val-required="*"
                        data-val-range="*"
                        data-val-range-min="0.00000001"
                        autocomplete="off"
                    />
                    <span class="currency upper text-label">{{ symbol.metadata.base_symbol }}</span>
                </label>
            </div>
            <div class="row total" growing-ignore="true">
                <div class="col-auto text-label">{{ $t('exchange.create_order_volume') }}</div>
                <div class="col text-content">
                    <span class="total-value">{{ totalValue }}</span>
                    {{ symbol.quote_symbol }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button class="btn-submit" :class="{ 'bg-buy': isBuyOrder, 'bg-sell': !isBuyOrder, 'btn-loading': submitting }" :disabled="submitting">
                        {{ isBuyOrder ? $t('exchange.orders_buy') : $t('exchange.orders_sell') }} {{ symbol.metadata.base_symbol }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    props: ['symbol', 'available_balance', 'type', 'visible_type'],

    data() {
        return { submitting: false, order_price: '', order_amount: '' };
    },

    watch: {
        /**
         * Make sure not exceed price precision
         */
        order_price: function () {
            let updated = this.limitPrecison(this.order_price, this.symbol.metadata.pricePrecision);

            // does the value changed?
            if (updated !== this.order_price) {
                this.order_price = updated;
            }
        },

        /**
         * Make sure not exceed volume precision
         */
        order_amount: function () {
            let updated = this.limitPrecison(this.order_amount, this.symbol.metadata.volumePrecision);

            // does the value changed?
            if (updated !== this.order_amount) {
                this.order_amount = updated;
            }
        }
    },

    computed: {
        isBuyOrder: function () {
            return this.type === 'buy';
        },

        orderType: function () {
            // 1: ExchangeTransactionType.BuyLimited
            // 2: ExchangeTransactionType.SellLimited
            return this.isBuyOrder ? 1 : 2;
        },

        totalValue: function () {
            let price = this.order_price * 1;
            let amount = this.order_amount * 1;

            if (!isNaN(price) && !isNaN(amount)) {
                const precision = this.symbol.metadata.pricePrecision + this.symbol.metadata.volumePrecision;
                let total = (price * amount).toFixed(precision);
                return total;
            } else {
                return '--';
            }
        }
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        setOrderPrice: function (p) {
            this.order_price = p;
        },

        createOrder: function (e) {
            const frm = $(e.target);
            const self = this;

            if (frm.valid()) {
                let data = frm.serializeAsJson();
                self.submitting = true;

                $.callPostApi(this, '/api/v1/exchange/create', data)
                    .then((json) => {
                        self.submitting = false;

                        if (json.errcode === 0) {
                            $.top_alert(self.$t('general.operation_succeeded'));

                            // _start_load_pending_orders();
                            self.$emit('order-created');

                            // Update balance.
                            self.syncBalance();
                        } else {
                            $.top_error(json.errmsg);
                        }
                    })
                    .catch((err) => {
                        self.submitting = false;

                        $.top_error(self.$t('general.operation_error'));
                        console.log(`Failed to create order: ${err}`);
                    });
            }
        },

        /**
         * Sync current balance.
         */
        syncBalance: function () {},

        limitPrecison: function (orig, precision) {
            const _number_pattern = /[^\d\.]/gi;

            // remove all non-digital characters
            let val = (orig + '').replace(_number_pattern, '');

            // find the decimal point
            let pos = val.indexOf('.');
            if (pos >= 0) {
                if (precision <= 0) {
                    // no digits allowed at all?
                    val = val.substr(0, pos);
                } else if (val.length - pos - 1 > precision) {
                    val = val.substr(0, pos + precision + 1);
                }
            }

            return val;
        }
    }
};
</script>
<style scoped>
.single-panel input{
    border: 1px solid #E1E2E6;
}
</style>