<template>
    <section class="page posts-page">
        <loading-indicator v-if="!categories" />
        <template v-else>
            <div v-for="(c, index) in categories" :key="index" class="container">
                <div class="row">
                    <div class="col">
                        <h1>
                            {{ c.category.name }}
                        </h1>
                    </div>
                </div>
                <div v-if="c.items" class="row">
                    <!-- posts within the category. -->
                    <posts-component :posts="c.items" />
                </div>
            </div>
        </template>

    </section>
</template>

<script lang="js">
import PostsComponent from './Components/_Posts.vue';

export default {
    components: { PostsComponent },

    data() {
        return {
            categories: null
        };
    },

    watch: {
        /**
         * Update post content after the language is changed.
         */
        '$i18n.locale': function (newVal, oldVal) {
            this.initPageAsync();
        }
    },

    mounted() {
        this.initPageAsync();
    },

    methods: {
        initPageAsync: async function () {
            const json = await $.callPostApi(this, '/api/v1/post/college?count=100');
            if (json && json.errcode === 0) {
                this.categories = json.data;
            }
        }
    }
};
</script>