import { render, staticRenderFns } from "./BottomNav-4.2.vue?vue&type=template&id=34db413c&scoped=true"
import script from "./BottomNav-4.2.vue?vue&type=script&lang=js"
export * from "./BottomNav-4.2.vue?vue&type=script&lang=js"
import style0 from "./BottomNav-4.2.vue?vue&type=style&index=0&id=34db413c&prod&scoped=true&lang=css"
import style1 from "./BottomNav-4.2.vue?vue&type=style&index=1&id=34db413c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34db413c",
  null
  
)

export default component.exports