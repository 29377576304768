<template>
    <section class="page settings-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('register.label_email') }}</h1>
                    <div class="text-muted">{{ $t('user.email_link_desp') }}</div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <loading-indicator v-if="!profile" />
                    <div v-else-if="profile.email" class="py-3">
                        {{ $t('user.email_linked', { email: profile.email }) }}
                    </div>
                    <div v-else class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                    <edit-email-component :profile="profile" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import EditEmailComponent from './Components/_EditEmail.vue';
import { setProfile } from 'utilities/helper';

export default {
    components: { EditEmailComponent },
    data() {
        return {
            profile: null
        }
    },

    mounted() {
        this.initAsync().catch((err) => {
            console.error(`ERROR: ${err}`);
        });
    },

    methods: {
        initAsync: async function () {
            const self = this;
            const json = await $.callPostApi(self, '/api/v1/account/profile');
            // Could be undefined if authentication is required.
            if (json && json.errcode === 0 && json.data) {
                setProfile(json.data);
                let profile = Object.freeze(json.data);
                self.profile = profile;
            }
        }
    }
}
</script>