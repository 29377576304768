<template>
    <div class="wrap-circles">
        <div class="circle" :style="`background-image: conic-gradient(var(--up-hover) ${Math.min(percentage * 100, 100)}%, var(--divider-primary) 0);`">
            <div class="inner">{{ Math.floor(percentage * 100) }}%</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['percentage']
};
</script>

<style>
.wrap-circles {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.circle {
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background: var(--divider-primary);
    overflow: hidden;
}

.circle .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2.75rem;
    height: 2.75rem;
    /* background: #000; */
    background: #f8f9fb;
    border-radius: 50%;
    /* font-size: 1.85em; */
    font-weight: 300;
    /* color: rgba(255, 255, 255, 0.75); */
}
</style>