<template>
    <section>
        <div class="table-control-boundary">
            <div v-if="records && records.length > 0" class="history-mobile">
                <div v-for="rec in records" v-bind:key="rec.id" class="history-item">
                    <div class="history-card-header">
                        <div class="currency"><span class="currency">{{ $t('balance.title_currency') }}</span>
                            {{ getTokenName(rec.currency) }}
                        </div>
                        <div class="time"><span>{{ $t('balance.title_time') }}</span>
                            {{ new Date(rec.timeCreated).formatDateTime() }}
                        </div>
                    </div>
                    <div class="card-item"><span>{{ $t('balance.title_balance') }}</span> <span
                            class="color-up"><span>{{
                                rec.currentBalance }}</span>
                            <div v-if="rec.balanceChange != 0"
                                :class="{ 'color-up': rec.balanceChange > 0, 'color-down': rec.balanceChange < 0 }">
                                {{ rec.balanceChange > 0 ? '+' + rec.balanceChange : rec.balanceChange }}
                            </div>
                        </span>
                    </div>
                    <div class="card-item"><span>{{ $t('balance.title_frozen') }}</span>
                        <div>{{ rec.currentFrozen }}</div>
                        <div v-if="rec.frozenChange != 0"
                            :class="{ 'color-up': rec.frozenChange > 0, 'color-down': rec.frozenChange < 0 }">
                            {{ rec.frozenChange > 0 ? '+' + rec.frozenChange : rec.frozenChange }}
                        </div>
                    </div>
                    <div class="card-item description"><span>{{ $t('balance.title_description') }}</span>
                        {{ rec.description && (rec.type === 20 || rec.type === 26) ? rec.description :
                            translate(rec.type, rec.description) }}
                    </div>
                </div>
            </div>
        </div>

        <loading-indicator v-if="!records" />
        <div v-else-if="records.length === 0" class="no-data-indicator m-5">{{ $t('general.no_data') }}</div>
        <div v-else class="d-flex justify-content-center">
            <button v-if="prevUids.length > 0" class="btn btn-default text-muted" v-on:click="goPrevPageAsync()">{{
                $t('balance.label_prev_page') }}</button>
            <button v-if="hasMoreRecords" class="btn btn-default text-muted" v-on:click="goNextPageAsync()">{{
                $t('balance.label_next_page') }}</button>
        </div>
    </section>
</template>

<style scoped>
.btn {
    margin: 1rem 0;
}

.table td,
.table th {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.table-control-boundary {
    width: 100%;
}


.history-mobile {
    background-color: #F5F5F6;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: block;
    margin-bottom: -8px;
    padding: 1rem;
    color: #191919;
}

.history-mobile .history-item {
    border-bottom: 1px solid #202020;
    margin: 1rem 0 1.3rem;
}

.history-card-header {
    display: flex;
    justify-content: space-between;
}

.card-item {
    line-height: 1.5;
}

.description {
    margin-bottom: 1rem;
}

.history-card-header .currency {
    font-size: 15px;
    font-weight: 700;
}
</style>

<script>
const bct_map = {};

export default {
    data() {
        return {
            records: null,

            hasMoreRecords: false,
            until_uid: 0,

            // An array to store starting uids for previous pages.
            prevUids: [],

            // The current language code.
            lang: null,
        };
    },

    watch: {
        '$i18n.locale': function () {
            // Always use lower case to read customized balance change types.
            this.lang = (this.$i18n.locale || 'en').toLowerCase();
        }
    },

    created() {
        // Remember all balance change types.
        $(this.sysconfig.bcts).each((_, val) => {
            bct_map[val] = true;
        });

        // Start to query records from server.
        this.goNextPageAsync();

        // Always use lower case to read customized balance change types.
        this.lang = (this.$i18n.locale || 'en').toLowerCase();
    },

    methods: {
        ///////////////////////////////////////////////////////////////////////////////////////////
        // Query records
        ///////////////////////////////////////////////////////////////////////////////////////////
        goNextPageAsync: async function () {
            // Remember the id of the first record in the current page.
            if (this.records && this.records.length > 0) {
                this.prevUids.push(this.records[0].uid + 1); // +1: because the query will exclude the specified uid itself.
            }

            this.records = null;
            try {
                const uid = this.until_uid;
                const json = await $.callPostApi(this, '/api/v1/finance/history?uid=' + encodeURIComponent(uid));
                this.records = json.data;
                if (json.data && json.data.length) {
                    this.until_uid = json.data[json.data.length - 1].uid;
                }
                this.hasMoreRecords = json.data.length === json.page_size;
            } catch (err) {
                console.error(`ERROR: ${err}`);
                $.top_error(this.$t('general.http_error'));
            }
        },

        goPrevPageAsync: async function () {
            if (this.prevUids.length > 0) {
                this.records = null;

                const uid = this.prevUids.pop();
                if (!isNaN(uid) && uid > 0) {
                    try {
                        console.log(`### start from ${uid}`);
                        const json = await $.callPostApi(this, '/api/v1/finance/history?uid=' + encodeURIComponent(uid));

                        this.records = json.data;
                        if (json.data && json.data.length) {
                            this.until_uid = json.data[json.data.length - 1].uid;
                        }
                        this.hasMoreRecords = json.data.length === json.page_size;
                    } catch (err) {
                        console.error(`ERROR: ${err}`);
                        $.top_error(this.$t('general.http_error'));
                    }
                }
            }
        },

        getTokenName: function (token) {
            switch (token) {
                case 'FTUSDT':
                    return this.$t('assets.label_futures_account');
                case 'INCT':
                    return this.$t('assets.label_commission');
                default:
                    return token;
            }
        },

        translate: function (type, desp) {
            if (type < 100) {
                return this.$t(bct_map[type] === true ? 'bct.' + type : 'bct.others');
            } else {
                return (this.sysconfig.customBcts[this.lang] || {})[type] || desp;
            }
        }
    }
};
</script>
