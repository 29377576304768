import { render, staticRenderFns } from "./ContentBottom.vue?vue&type=template&id=6046bca6&scoped=true"
import script from "./ContentBottom.vue?vue&type=script&lang=js"
export * from "./ContentBottom.vue?vue&type=script&lang=js"
import style0 from "./ContentBottom.vue?vue&type=style&index=0&id=6046bca6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6046bca6",
  null
  
)

export default component.exports