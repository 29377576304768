<template>
    <div>
        <div class="head-row">
            <div class="tcell">{{ $t('exchange.orders_create_time') }}</div>
            <div class="tcell">{{ $t('exchange.orders_symbol') }}</div>
            <div class="tcell">{{ $t('exchange.orders_side') }}</div>
            <div class="tcell">{{ $t('exchange.orders_amount') }}</div>
            <div class="tcell">{{ $t('exchange.orders_price_long') }}</div>
            <div class="tcell">{{ $t('exchange.orders_executed_long') }}</div>
            <div class="tcell">{{ $t('exchange.orders_avg_price') }}</div>
            <div class="tcell">{{ $t('exchange.orders_fee') }}</div>
            <div class="tcell">{{ $t('exchange.label_status') }}</div>
        </div>
        <div class="table-row" v-for="order in exchange_orders" :key="order.order_id">
            <div class="tcell">
                <label>{{ $t('exchange.orders_create_time') }}</label>
                <div>{{ order.time_created }}</div>
            </div>
            <div class="tcell">
                <label>{{ $t('exchange.orders_symbol') }}</label>
                <div>{{ order.symbol_name }}</div>
            </div>
            <div class="tcell">
                <label>{{ $t('exchange.orders_side') }}</label>
                <div v-bind:class="order.buy ? 'color-up' : 'color-down'">
                    <span v-if="order.buy">{{ $t('exchange.orders_buy') }}</span>
                    <span v-else>{{ $t('exchange.orders_sell') }}</span>
                </div>
            </div>
            <div class="tcell">
                <label>{{ $t('exchange.orders_amount') }}</label>
                <div>
                    {{ order.amount }} <small class="text-muted">{{ order.base_currency }}</small>
                </div>
            </div>
            <div class="tcell">
                <label>{{ $t('exchange.orders_price_long') }}</label>
                <div>
                    {{ order.limited_price }} <small class="text-muted">{{ order.quote_currency }}</small>
                </div>
            </div>
            <div class="tcell">
                <label>{{ $t('exchange.orders_executed_long') }}</label>
                <div>
                    {{ order.completed_amount }} <small class="text-muted">{{ order.base_currency }}</small>
                </div>
            </div>
            <div class="tcell">
                <label>{{ $t('exchange.orders_avg_price') }}</label>
                <div>
                    {{ order.avg_price }} <small class="text-muted">{{ order.quote_currency }}</small>
                </div>
            </div>
            <div class="tcell">
                <label>{{ $t('exchange.orders_fee') }}</label>
                <div>
                    {{ order.fee }} <small class="text-muted">{{ order.fee_currency }}</small>
                </div>
            </div>
            <div class="tcell">
                <label>{{ $t('exchange.label_status') }}</label>
                <div>
                     <span v-if="order.status === 0">
                        <a href="javascript:" @click="cancelOrder(order)" class="pull-right cancel-button">{{ $t('general.cancel') }}</a>
                    </span>
                    <span v-else-if="order.status === 1">{{ $t('exchange.status_completed') }}</span>
                    <span v-else>{{ $t('exchange.status_cancelled') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['exchange_orders'],

    methods: {
        cancelOrder: function (order) {
            const self = this;

            // *************************************************************
            // Post to server to cancel an order
            $.callPostApi(this, '/api/v1/exchange/cancelorder?id=' + encodeURIComponent(order.metadata.orderId))
                .then((json) => {
                    if (json && json.errcode === 0) {
                        $.top_alert(self.$t('general.operation_succeeded'));

                        // mark the status as unknown.
                        order.status = -1;
                    } else {
                        $.top_error(json.errmsg);
                    }
                })
                .catch(function (err) {
                    console.error(`ERROR: ${err}`);
                    $.top_error(self.$t('general.operation_error'));
                    return;
                });
        }
    }
};
</script>