<template>
    <div class="table" v-if="filteredSymbols.length > 0">
        <section class="homepage-market d-none d-md-block">
            <dl>
                <dd class="title">
                    <dl>{{ $t('orders.label_symbol') }}</dl>
                    <dl>{{ $t('home.price') }}</dl>
                    <em>{{ $t('home.price_change') }}</em>
                </dd>
                <dd v-for="(symbol, index) in filteredSymbols" :key="index" @click="gotoTrade(symbol)" class="item">
                    <dl>
                        <dt class="text-uppercase d-flex align-items-center token-name">
                            <div class="pe-2"><token-icon :symbol="symbol" /></div>
                            <div>{{ symbol.metadata.name }}</div>
                        </dt>
                    </dl>
                    <dl>
                        <dt growing-ignore="true">${{ symbol.price }}</dt>
                        <dd growing-ignore="true">{{ symbol.price_change }}</dd>
                    </dl>
                    <em :class="symbol.up ? 'price-up' : 'price-down'" growing-ignore="true">{{ symbol.price_change_pct }}</em>
                </dd>
            </dl>
        </section>
    </div>
</template>

<script>
import { getAppContext } from 'utilities/helper';
import TokenIcon from './TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['title', 'symbols', 'typeFilter', 'searchTerm'],
    computed: {
        filteredSymbols: function () {
            if (!this.symbols) {
                return [];
            }

            // Determines if test mode is enabled or not for the current user.
            const context = getAppContext();
            let testMode = false;
            if (context && context.profile) {
                testMode = context.profile.testEnabled === true;
            }

            const self = this;
            const term =
                this.searchTerm && typeof this.searchTerm === 'string'
                    ? this.searchTerm.trim().toUpperCase() // Always use upper case
                    : '';
            if (term && term.length > 0) {
                let data = this.symbols.filter((s) => {
                    return s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly) && s.metadata.name.indexOf(term) >= 0;
                });
                if(this.$route.path==='/'){
                    data= data.slice(0,5);
                }
                return data
            } else {
                // Return symbols for the specified type.
                let data = this.symbols.filter((s) => {
                    return s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly);
                });
                if(this.$route.path==='/'){
                    data= data.slice(0,5);
                }
                return data
            }
        }
    },
    methods: {
        gotoTrade: function (sym) {
            this.$emit('symbol-selected', sym);
        },

        getVisibleSids: function () {
            const output = [];
            $(this.filteredSymbols).each((index, sym) => {
                output.push(sym.metadata.id);
            });

            return output;
        }
    }
};
</script>
<style scoped>
.table,.homepage-market{
    height: 100%;
}
.homepage-market .market-wrap a:hover{
    background-color: #fff;
}
.homepage-market .market-wrap dl dd a .buy span {
    background: #5235E8;
    border-radius: 4px 4px 4px 4px;
}
.homepage-market dd{
    display: flex;
}
.homepage-market dd dl div,.homepage-market dd dl dt{
    font-size: 12px;
    font-weight: 500;
}
.homepage-market dd dl:nth-child(1){
    width: 42%;
}
.homepage-market dd dl:nth-child(2){
    width: 29%;
}
.homepage-market dd em{
    width: 29%;
    background: none !important;
    text-align: center;
}
.title{
    background-color: #FBFCFE;
}
.title dl,.title em{
    font-size: 8px;
    font-weight: 600;
}
.homepage-market>dl>dd.title>* {
    line-height: 56px;
}
.item{
    height: 55px;
}
.homepage-market>dl>dd dl dt {
    font-size: 1rem;
    font-weight: 400;
    white-space: nowrap;
}
.homepage-market>dl>dd dl dd {
    font-size: 0.9rem;
    opacity: .7;
}
.price-up{
    color: #58bd7d 
}
.price-down{
    color: #FB3766 
}
</style>