<template>
    <div class="row">
        <div class="col balance-list">
            <div class="total">
                <div class="actions"></div>
            </div>

            <dl class="title-row">
                <dt>
                    <div class="hidden-sm">{{ $t('assets.account_type') }}</div>
                    <div>{{ $t('assets.balance') }}</div>
                    <div>{{ $t('assets.available_balance') }}</div>
                    <div>{{ $t('assets.frozen') }}</div>
                </dt>
            </dl>

            <loading-indicator v-if="!balance_list" />

            <dl v-else v-for="(balance, index) in balance_list" class="currency-row" :key="balance.currency">
                <dd>
                    <div>{{ getTokenName(balance) }}</div>
                    <div>{{ balance.balance }}</div>
                    <div v-if="balance.currency === 'FTUSDT'">{{ futures_assets }}</div>
                    <div v-else>{{ balance.available }}</div>
                    <div>{{ balance.frozen }}</div>
                </dd>

                <!-- display deposit address -->
                <div :key="index + '-deposit-addr'" v-if="show_deposit_address && balance.currency === 'USDT'" class="deposit-address-block">
                    <deposit-form :bcConfig="bcConfig" :coinConfig="{ currency: 'USDT', minDepositAmount: 1 }" />
                </div>
            </dl>

        </div>
    </div>
</template>

<script>
import DepositForm from '../../Finance/Components/DepositForm.vue';

export default {
    components: { DepositForm },
    props: ['balance_list', 'show_deposit_address', 'futures_assets', 'bcConfig'],

    methods: {
        getTokenName: function (token) {
            switch (token.currency) {
                case 'FTUSDT':
                    return this.$t('assets.label_futures_account');
                case 'INCT':
                    return this.$t('assets.label_commission');
                default:
                    return token.display_name;
            }
        }
    }
}
</script>
<style scoped>
.currency-row{
    border-bottom: 1px solid #EDEEEF;
}
</style>