<template>
    <footer class="footer">
        <div class="footer-inner container">
            <div class="footer-right" v-if="footer">
                <dl>
                    <dt>{{ footer[0].title }}</dt>
                    <dd>
                        <router-link v-for="item in footer[0].items" :key="item.id"
                            rel="noopener noreferrer nofollow"
                            :to="'/post/' + item.id">{{ item.title }}
                        </router-link>
                    </dd>
                </dl>
                <!-- <dl class="last">
                    <dd>
                        <div>{{ $t('home.risk_notice_line1') }}</div>
                        <div>{{ $t('home.risk_notice_line2', { company: sysconfig.company }) }}</div>
                        <div>{{ $t('home.risk_notice_line3') }}</div>
                    </dd>
                </dl> -->
            </div>
            <div class="footer-languages" v-if="langs.length > 0">
                <div v-if="footer && footer.length > 0">
                    <router-link v-for="item in footer[0].items" :key="item.id" rel="noopener noreferrer nofollow" :to="'/post/' + item.id">{{ item.title }}</router-link>
                </div>
                <div>
                    <a v-for="(lang, index) in langs" :key="index" @click="changeLang(lang)" :disabled="$i18n.locale === lang" href="javascript:;">
                        {{ getLocaleName(lang) }}
                    </a>
                </div>
            </div>
            <div>
                <div class="footer-logo"><img src="/image/sitelogosm" :alt="sysconfig.name" /></div>
                <div>
                    &copy; 2024 {{ sysconfig.name }}
                    <br />
                    {{ $t('home.footer_support_email') }}: {{ sysconfig.email }}
                </div>
                 <div>
                    <a :href="sysconfig.kvConfigs[`twitter_${$i18n.locale}`]?sysconfig.kvConfigs[`twitter_${$i18n.locale}`]:sysconfig.kvConfigs.twitter" target="_blank">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-twitter" />
                        </svg>
                    </a>
                    <a :href="sysconfig.kvConfigs[`facebook_${$i18n.locale}`]?sysconfig.kvConfigs[`facebook_${$i18n.locale}`]:sysconfig.kvConfigs.facebook" target="_blank">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-facebook" />
                        </svg>
                    </a>
                    <a :href="sysconfig.kvConfigs[`instagram_${$i18n.locale}`]?sysconfig.kvConfigs[`instagram_${$i18n.locale}`]:sysconfig.kvConfigs.instagram" target="_blank">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-instagram" />
                        </svg>
                    </a>
                    <a :href="sysconfig.kvConfigs[`youtube_${$i18n.locale}`]?sysconfig.kvConfigs[`youtube_${$i18n.locale}`]:sysconfig.kvConfigs.youtube" target="_blank">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-youtube" />
                        </svg>
                    </a>
                    <a :href="sysconfig.kvConfigs[`medium_${$i18n.locale}`]?sysconfig.kvConfigs[`medium_${$i18n.locale}`]:sysconfig.kvConfigs.medium" target="_blank">
                        <svg class="ft-icon" viewBox="0 0 256 256">
                            <use xlink:href="/dist/svg/icons.svg#icon-medium" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { getAppContext } from 'utilities/helper';
import '@/assets/svg/logo/icon-twitter.svg';
import '@/assets/svg/logo/icon-facebook.svg';
import '@/assets/svg/logo/icon-instagram.svg';
import '@/assets/svg/logo/icon-youtube.svg';
import '@/assets/svg/logo/icon-medium.svg';
export default {
    data() {
        return { langs: [], context: null };
    },

    computed: {
        footer: function () {
            return this.context.homeConfig ? this.context.homeConfig.footer : null;
        }
    },

    created() {
        this.langs = Object.freeze(this.sysconfig.supportedLanguages);
        this.context = getAppContext();
    },

    methods: {
        changeLang: function (lang) {
            $.changeLang(this, lang);
        }
    }
};
</script>
<style scoped>
.footer{
   background: #000;
   position: relative;
   color: #fff;
}
.ft-icon {
    fill: #fff;
    stroke: #000;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    margin-right: .5rem;
}
.footer-right a{
    color: #fff;
}
</style>