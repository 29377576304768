<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="home_quotes">
                    <loading-indicator v-if="!symbols" />
                    <template v-else>
                        <HomeSymbolList ref="sym_list" :symbols="symbols" :typeFilter="2" @symbol-selected="gotoTradePage" :futures="true"/>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

hr {
    background-color: var(--divider-secondary);
    opacity: 0.1;
}
</style>

<script>
import HomeSymbolList from '../../Home/Components/HomeSymbolList.vue';
import InlineSvgIcons from '@/pages/Components/_InlineSvgIcons.vue';
export default {
    components: { HomeSymbolList,InlineSvgIcons },

    props: ['symbols'],

    methods: {
        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },

        getVisibleSids: function () {
            return this.symbols ? this.$refs.sym_list.getVisibleSids() : [];
        }
    }
};
</script>