<template>
    <section class="page help-page support-iframe pb-5">
        <div class="container">
            <div class="row">
                <div class="col">
                    <!-- loader -->
                    <loading-indicator v-if="!notice" />

                    <!-- notice -->
                    <template v-else>
                        <h1 class="page-title" v-html="notice.title"></h1>
                        <h2 class="sub-title">
                            {{ notice.author }}
                            {{ new Date(notice.timeCreated).formatDate() }}
                        </h2>
                        <pre class="agreement" v-html="notice.body"></pre>
                    </template>
                </div>
            </div>
            <div class="row" v-if="all_notices && all_notices.length > 0">
                <div class="col history" >
                    <template >
                        <div class="d-flex" v-for="entity in paged_notices" :key="entity.id">
                            <img src="../../assets/images/v4.2/notices.png" />
                            <router-link class="flex-fill text-wrap" :to="'/notice/' + entity.id">
                                <div v-html="entity.title"></div>
                            </router-link>
                           <div class="text-nowrap ps-3">{{ new Date(entity.timeCreated).formatDate() }}</div>
                        </div>

                        <vue-pager :total="all_notices.length" :page_size="page_size" :page_index="page_index" @page-changed="onPageChanged" />
                    </template>
                </div>
            </div>
        </div>

        <ext-resource-i-frame />
    </section>
</template>

<script type="text/javascript">
import ExtResourceIFrame from '../Components/ExtResourceIFrame.vue';
import VuePager from '../Components/VuePager.vue';

export default {
    components: { ExtResourceIFrame, VuePager },
    props: ['id'],

    data: function () {
        return {
            notice: null,
            all_notices: [],

            // The current page index
            page_index: 0,
            page_size: 10
        };
    },

    computed: {
        paged_notices: function () {
            const input = this.all_notices;
            if (input && input.length > 0) {
                const minPageIndex = this.page_index * this.page_size;
                const maxPageIndex = (this.page_index + 1) * this.page_size;

                const output = input.filter((elm, index) => {
                    return index >= minPageIndex && index < maxPageIndex;
                });
                console.log(`#### output length = ${output.length}`);
                return output;
            }

            return [];
        }
    },

    mounted() {
        this.readNoticeFromServer(this.id);
        this.readAllNotices();
    },

    methods: {
        readNoticeFromServer: function (id) {
            const self = this;
            self.notice = null;

            setTimeout(() => {
                $.callGetApi(self, '/api/v1/notice?id=' + encodeURIComponent(id)).then((json) => {
                    if (json.errcode === 0) {
                        self.notice = json.data;

                        Vue.nextTick(() => {
                            $.scanPopup();
                        });
                    } else {
                        self.$router.push('/notfound');
                    }
                });
            }, 10);
        },

        readAllNotices: function () {
            const self = this;
            $.callGetApi(self, '/api/v1/notices').then((json) => {
                if (json.errcode === 0 && json.data) {
                    self.page_index = 0;
                    self.all_notices = Object.freeze(json.data);
                }
            });
        },

        onPageChanged: function (pageIndex) {
            this.page_index = pageIndex;
        }
    },

    watch: {
        $route() {
            this.readNoticeFromServer(this.id);
        },

        /**
         * Update notice content after the language is changed.
         */
        '$i18n.locale': function (newVal, oldVal) {
            this.readNoticeFromServer(this.id);

            // BUGFIX:
            // Read the notice list for the specified language.
            this.readAllNotices();
        }
    }
};
</script>
<style scoped>
.container h1,.container h2{
    color: #000;
}
.page{
    background: #f9f9f9;
}
.help-page pre.agreement,.notice-list,.notice-list a{
    color: #000;
}
.help-page .sub-title{
    border-bottom: 1px solid #E6ECF2;
}
.page.help-page hr{
    border-top: 1px solid #E6ECF2;
    background-color:#E6ECF2;;
}
.updata{
    margin-bottom: 10px;
    height: 110px;
}
.updata,.history{
    padding: 10px 20px;
    background: #fff;
}
.updata a,.history a{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #242428;
}
.updata img,.history img{
    margin-right: 5px;
    height: 20px;
    width: 16px;
}
.updata .d-flex,.history .d-flex{
    padding: 10px 10px;
}
</style>