<template>
    <form method="post" action="javascript:void(0)" @submit="updateGAForAccount">
        <div class="form-group">
            <div class="row">
                <div class="col">
                    <label class="form-label">{{ $t('ga.label_scan') }}</label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <img :src="qrImage" :alt="$t('ga.title')" class="img-thumbnail" />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="form-label">{{$t('ga.secret')}}: </label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <span id="secret-code" class="fw-bold">{{ secret }}</span>
                    <a href="javascript:;" data-toggle="copy" data-target="#secret-code" class="copy-link">{{ $t('general.copy') }}</a>
                </div>
            </div>
        </div>

        <!-- Login Password -->
        <div class="form-group">
            <div class="row">
                <div class="col">
                    <label class="form-label">{{ $t('user.login_password') }}</label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <input name="Password" type="password" autocomplete="new-password" class="form-control" data-val="true" data-val-required="*" data-val-maxlength="*" data-val-maxlength-max="32" data-val-minlength="*" data-val-minlength-min="2" :placeholder="$t('user.login_password')" />
                </div>
            </div>
            <div class="help-block">{{ $t('ga.label_password_desp') }}</div>
        </div>

        <!--
        GA Pin is only required when it was set before.
        -->
        <div class="form-group">
            <div class="row">
                <div class="col">
                    <label class="form-label">{{ $t('ga.label_code') }}</label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <input name="GAPin" type="text" autocomplete="off" pattern="\d*" maxlength="6" class="form-control" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^\d{6}$" :placeholder="$t('login.ga_pin_placeholder')" />
                </div>
            </div>
            <div class="help-block">{{ $t('ga.label_code_desp') }}</div>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col">
                    <button type="submit" class="btn btn-submit" :class="{ 'btn-completed': completed, 'btn-loading': submitting }" :disabled="submitting || completed">
                        {{ $t('general.submit') }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { setProfile } from 'utilities/helper';

export default {
    props: ['qrImage','secret'],

    data() {
        return {
            email: null,
            submitting: false,
            completed: false,
        }
    },

    mounted() {
        $.resetValidators();
        Vue.nextTick(() => {
            $('a[data-toggle=copy]').enable_copy();
        });
    },

    methods: {
        updateGAForAccount: function (e) {
            const frm = $(e.target);
            if (!frm.valid()) return false;

            // post the server
            const data = frm.serialize();
            const self = this;
            self.submitting = true;

            $.callPostApi(self, '/api/v1/account/ga', data)
                .then((json) => {
                    if (json && json.errcode === 0) {
                        try {
                            // Save the current authenticated user.
                            setProfile(json.data);

                            // redirect to home page
                            self.completed = true;

                            setTimeout(function () {
                                self.$router.push('/user');
                            }, 500);
                        } catch (err) {
                            console.error(err);
                        }
                    } else {
                        $.top_error(json.errmsg);
                    }
                })
                .catch(function (err) {
                    $.top_error(self.$t('general.operation_error'));
                })
                .then(() => {
                    self.submitting = false;
                });
        }
    }
}
</script>
<style scoped>
.btn-submit:hover{
    font-weight: bold;
}
.copy-link {
    color: #006cff;
    border-bottom: 1px solid;
    font-weight: 700;
}
</style>